import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faDownload } from '@fortawesome/pro-light-svg-icons'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { faLoader } from '@fortawesome/pro-duotone-svg-icons'
import './App.scss';
import SuperHeroImg from './superhero.png';
import LeadGenMatching from './SC-Superhero-LeadGen-Matching.png';
import LeadGenPeer from './SC-Superhero-LeadGen-Peer.png';
import LeadGenStock from './SC-Superhero-LeadGen-Stock.png';

class SuperHeroPledgeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    // console.log('componentDidMount');
  }

  componentWillUnmount() {
    // console.log('componentWillUnmount');
  }

  handleSubmit( event=false ) {
    if(event)
      event.preventDefault();

    var formSubmitBtn = document.getElementById('superHeroPledgeFormSubmitBtn');
    
    formSubmitBtn.classList.add('btn--loading');

    var formData = new FormData(event.target);
    let formUrl = event.target.getAttribute('action');

    try {
      fetch(formUrl, {
        method: "POST",
        body: new URLSearchParams(formData),
        mode: "cors",
      })
      .then((res) => {
        // console.log(res);
      });

      formSubmitBtn.classList.remove('btn--loading');
      this.setState({ submitted: true });
    } catch( err ) {
      console.log(err);
    }
  }

  render() {
    return (
      <div className="SuperHeroPledge--Form">
        
        { this.state.submitted === false && 
          <>
            <header className="SuperHeroPledge--App-header">
              Send a Superhero Book to a Patient
            </header>

            <div className="SuperHeroPledge--App-subHeader">
              Our donors help patients like Mason soar to new heights! By joining our email list, you’ll:
              <ul>
                <li>Enable us to send a superhero comic book to a patient to lift their spirits</li>
                <li>Learn about the world-class care we provide to children in need</li>
                <li>Stay connected with the inspiring work we do to help young patients heal and thrive</li>
              </ul>
            </div>

            <div className="SuperHeroPledge--App-layout">
              
              <div className="SuperHeroPledge--Pledge-container">
                <img id="SuperHeroImg" src={SuperHeroImg} />
              </div>

              <div className="SuperHeroPledge--Form-container">
                <div className="SuperHeroPledge--Form-header">Sign Up for Emails</div>

                <form id="superHeroPledgeForm" action="https://classyapi.crosby.co/api/subscribe" method="post" onSubmit={this.handleSubmit}>
                  <input type="hidden" name="form_id" value="superhero-pledge-2025" />
                  <div className="row">
                    <div className="col">
                      <input type="text" name="first_name" className="form-control" placeholder="First name" aria-label="First name" required/>
                    </div>
                    <div className="col">
                      <input type="text" name="last_name" className="form-control" placeholder="Last name" aria-label="Last name" required/>
                    </div>
                    <div className="col-12">
                      <input type="email" name="email" className="form-control" placeholder="Email" aria-label="Email" required/>
                    </div>
                    <div className="col-12">
                      <button type="submit" id="superHeroPledgeFormSubmitBtn" className="btn">Submit <FontAwesomeIcon id="superHeroPledgeFormLoadingIcon" icon={faLoader} spinPulse/></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        }

        { this.state.submitted === true && 
          <div>
            <header className="SuperHeroPledge--App-header">
              Thank you!
            </header>

            <div className="SuperHeroPledge--Form-intro">
              Your email has been added to our mailing list and a superhero comic book is on it’s way to one of our patients. We will be in touch soon.
            </div>

            <div className="SuperHeroPledge--Form-give">
              <div className="SuperHeroPledge--Form-give-content">
                <div className="SuperHeroPledge--Form-give-header">Other Ways to Give</div>

                <div className="SuperHeroPledge--Form-give-wrapper">
                  <div className="SuperHeroPledge--Form-give-col">
                    <div className="SuperHeroPledge--Form-give-img">
                      <img id="LeadGenMatching" src={LeadGenMatching} />
                    </div>
                    <div className="SuperHeroPledge--Form-give-heading">
                      Matching Gifts
                    </div>
                    <div className="SuperHeroPledge--Form-give-desc">
                      Many employers will match your gift dollar for dollar so you can DOUBLE the impact of your generosity and support. Make a gift today and see if your employer will match!
                    </div>
                    <div className="SuperHeroPledge--Form-give-btn">
                      <a className="btn" href="https://lovetotherescue.org/how-you-can-help/ways-to-give/matching-gifts" target="_blank">Learn More</a>
                    </div>
                  </div>
                  <div className="SuperHeroPledge--Form-give-col">
                    <div className="SuperHeroPledge--Form-give-img">
                      <img id="LeadGenPeer" src={LeadGenPeer} />
                    </div>
                    <div className="SuperHeroPledge--Form-give-heading">
                      Peer-to-Peer Giving
                    </div>
                    <div className="SuperHeroPledge--Form-give-desc">
                      Your support, whether starting your own fundraiser, joining a campaign, or spreading the word, helps us provide life-changing care to patients in need.
                    </div>
                    <div className="SuperHeroPledge--Form-give-btn">
                      <a className="btn" href="https://lovetotherescue.org/how-you-can-help/fundraise-for-us/learn-how-to-fundraise" target="_blank">Learn More</a>
                    </div>
                  </div>
                  <div className="SuperHeroPledge--Form-give-col">
                    <div className="SuperHeroPledge--Form-give-img">
                      <img id="LeadGenStock" src={LeadGenStock} />
                    </div>
                    <div className="SuperHeroPledge--Form-give-heading">
                      Stock Gifting
                    </div>
                    <div className="SuperHeroPledge--Form-give-desc">
                      Donors’ gifts change lives at Shriners Children’s, and giving through stock is a powerful way to make a difference. Put stock in something that matters!
                    </div>
                    <div className="SuperHeroPledge--Form-give-btn">
                      <a className="btn" href="https://lovetotherescue.org/how-you-can-help/ways-to-give/stock-gifting" target="_blank">Learn More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        }
      </div>
    );
  }
}

class App extends Component {
  render() {
    return (
      <div className="SuperHeroPledge--App">

        <SuperHeroPledgeForm/>

      </div>
    );
  }
}

export default App;
